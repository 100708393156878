<template>
  <div class="carLoanSuccess-box">
    <div class="u-flex-col u-col-center carLoanSuccess-item">
      <img
        class="icon-success"
        src="../assets/images/icon_submit_success.png"
        alt=""
      />
      <div class="carLoanSuccess-tit">车贷申请提交成功</div>
      <div class="carLoanSuccess-ntit">
        您的申请已成功提交，请耐心等待审核结果
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarLoanSuccess",
};
</script>

<style lang="scss" scoped>
.carLoanSuccess-box {
  .carLoanSuccess-item {
    margin-top: 2.46rem;
  }

  .icon-success {
    width: 2.62rem;
    height: 2.24rem;
    margin-bottom: 0.46rem;
  }

  .carLoanSuccess-tit {
    font-size: 0.36rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #292929;
    line-height: 0.5rem;
    margin-bottom: 0.16rem;
  }

  .carLoanSuccess-ntit {
    font-size: 0.26rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #b5b5b5;
    line-height: 0.36rem;
  }
}
</style>
